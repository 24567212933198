import React, { useContext, useMemo } from 'react';
import { motion, useAnimation } from 'framer-motion'
import { IntersectionContext } from '../IntersectionObserver'

const ItemAzul = (props) => {
    const { inView } = useContext(IntersectionContext);


    const variants = {
        hidden: {
            scale: 0,
            opacity: 0,
            
        },
        show: {
            scale: 1,
            opacity: 1,
            transition:{
                duration:1
            }
        }
    };
    
    return (


        <motion.section initial="hidden" animate={inView ? "show" : "hidden"} exit="hidden" variants={variants} className="wrapper style2 bolding">
            <div className="inner">
                <div className="flex flex-2">
                    <div className="col col2 sizing">
                        <h3>{props.titulo} </h3>
                        <p>{props.info.descripccion}</p>
                    </div>
                    <div className="col col1 first">
                        <div className="image round fit">
                            <img src={require(`../images/${props.info.src}`)} />
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default ItemAzul;