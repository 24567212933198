
import 'antd/dist/antd.css';
import axios from 'axios'
import { Form, Input, Button, Select, message } from 'antd';
import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { IntersectionObserver, IntersectionContext } from '../IntersectionObserver'
const { Option } = Select



const Contacto = () => {
    const { inView } = useContext(IntersectionContext);
    const variants = {
        visible: i => ({
            x: 0,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 100,
                delay: i * 0.6,
            }

        }),
        initial: {
            x: -5000
        }
    }


    const submitHandler = values => {
        axios.post('https://diegamailgrunner.herokuapp.com/enviar', values)
            .then(response => {
                if (response.status === 200) {
                    message.success('Mensaje recibido, nos pondremos en contacto pronto')
                }
            })
            .catch(err =>{ console.log(err); message.error('Ha ocurrido un error')})
    }
    const prefixSelector = (
        <Form.Item name="prefijo" noStyle>
            <Select
                style={{
                    width: 90,
                }}
            >
                <Option value="+503">+503</Option>
                <Option value="+502">+502</Option>
                <Option value="+52">+52</Option>
                <Option value="otro">Otro</Option>
            </Select>
        </Form.Item>
    );
    return (
        <Form name="nest-messages" onFinish={submitHandler} style={{ textAlign: 'initial', display: 'flex', flexDirection: 'column' }}  >
            <motion.div initial="initial" custom={1} variants={variants} animate={inView ? "visible" : "initial"} exit="hidden" >
                <Form.Item
                    name='nombreCliente'

                    label="Nombre Completo"
                    rules={[
                        {
                            required: true, message: "Se requiere de su nombre"
                        },
                    ]}
                >
                    <Input style={{ width: '700px' }} />
                </Form.Item>
            </motion.div>
            <motion.div initial="initial" custom={2} variants={variants} animate={inView ? "visible" : "initial"} exit="hidden" >
                <Form.Item
                    name='email'
                    label="Correo electronico"
                    hasFeedback
                    rules={[
                        {
                            type: 'email', required: true, message: ' Se requiere de un email valido'
                        },
                    ]}
                >
                    <Input style={{ width: '700px' }} />
                </Form.Item>
            </motion.div>
            <motion.div initial="initial" custom={3} variants={variants} animate={inView ? "visible" : "initial"} exit="hidden" >
                <Form.Item
                    name="telefono"
                    label="Telefono"
                >
                    <Input addonBefore={prefixSelector} style={{ width: 'calc(100% - 10em)' }} />
                </Form.Item>
            </motion.div>
            <motion.div initial="initial" custom={4} variants={variants} animate={inView ? "visible" : "initial"} exit="hidden" >
                <Form.Item
                    name="servicio"
                    label="Servicio"
                    rules={[
                        {
                            required: true,
                            message: 'Selecciona un servicio!',
                        },
                    ]}
                >
                    <Select placeholder="Servicios" style={{ width: 'calc(100% - 2  0em)' }}>
                        <Option value="Maritimo">Maritimo</Option>
                        <Option value="Aereo">Aereo</Option>
                        <Option value="Terrestre">Terrestre</Option>
                    </Select>
                </Form.Item>
            </motion.div>
            <motion.div initial="initial" custom={5} variants={variants} animate={inView ? "visible" : "initial"} exit="hidden" >
                <Form.Item name='mensaje' label="Mensaje">
                    <Input.TextArea style={{ width: '700px' }} />
                </Form.Item>
            </motion.div>
            <motion.div initial="initial" custom={6} variants={variants} animate={inView ? "visible" : "initial"} exit="hidden" >
            <Form.Item >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
            </motion.div>
        </Form >
    );
};

export default Contacto
