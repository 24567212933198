import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import { Link, animateScroll as scroll } from "react-scroll";


const Example = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);


  return (
    <div>
      <Navbar expand="md" >

        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" style={{ justifyContent: 'center', alignItems: 'center' }} navbar>
            <NavItem >
              <a href="/" style={{ fontSize: '1.2em', color: '#fff' }}>Principal</a>
            </NavItem>
            <NavItem>
              <h1 style={{ fontSize: '1.2em', color: '#fff' }}>|</h1>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: '#fff' }} href="/Aereo">Aereo</NavLink>
            </NavItem>
            <NavItem>
              <h1 style={{ fontSize: '1.2em', color: '#fff' }}>|</h1>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: '#fff' }} href="/Terrestre" >Terrestre</NavLink>
            </NavItem>
            <NavItem>
              <h1 style={{ fontSize: '1.2em', color: '#fff' }}>|</h1>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: '#fff' }} href="/Maritimo">Maritimo</NavLink>
            </NavItem>
            <NavItem>
              <h1 style={{ fontSize: '1.2em', color: '#fff' }}>|</h1>
            </NavItem>
            <NavItem>
              <NavLink style={{ color: '#fff' }} href="/Ofrecemos">¿Que ofrecemos?</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Example;