import React from 'react';
import { Info } from '../assets/js/info'
import { Redirect } from 'react-router-dom';
import Footer from './Footer'
import Navbar from './Navbar'
import { Link, animateScroll as scroll } from "react-scroll";


const Servicio = (props) => {

    const servicio = props.match.params.Servicio
    let ser = false

    Info.forEach(element => {
        if (element.name === servicio) ser = element
    });
    return (
        <>
            {!ser && <Redirect to="/" />}
            <div className="bolding inner" style={{ backgroundColor: '#1bb7f5', position: 'fixed', top: '0px', width: '100%', zIndex: '999', color: '#fff' }} >
                <Navbar />
            </div>
            
            <div className='img2' style={{ paddingTop: '2em', minHeight: '100vh', color: ser.color }}>

                <section class="wrapper">
                    <div class="inner">
                        <header class="align-center">

                        </header>
                        <div class="flex flex-2">
                            <div class="col col2 sizing bolding">
                                
                                {ser.name=="Ofrecemos" &&
                                <>
                                    <div style={{marginTop:'5em'}}>
                                    <h3>¿Que ofrecemos?</h3>
                                    <p>-Atención las 24 horas del día.</p>
                                    <p>-Servicio Personalizado.</p>
                                    <p>-Aseguramos su carga.</p>
                                    <p>-Modalidad en marítimo, aéreo y terrestre a su conveniencia.</p>
                                    <p>-Movemos desde todos los lados del mundo.</p>
                                    </ div>
                                </>
                                }
                                {ser.name!=="Ofrecemos" &&
                                <>
                                    <h3>{ser.name}</h3>
                                    <p>{ser.descripcion}</p>
                                </>
                                }   
                            </div>
                            <div class="col col1 first">
                                <div class="image round fit">
                                    <a class="link"><img src={require(`../images/${ser.src}`)} alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div id="foot">
                <Footer />
            </div>
        </>
    );
};

export default Servicio;