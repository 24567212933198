const Info = [
    {
        name:"Maritimo",
        shortDes:"En el agua ",
        descripcion:"Contamos con una red de agentes dedicados a ofrecer servicio marítimo en Contenedores Completos, Equipos Especiales como Flat Rack, Open Top, etc así como también ofrecemos el servicio de Cargas Consolidadas, facilitando opciones de rutas, tiempos de transito y Tarifas competitivas."
        ,backgroundColor:"#73d7ff",
        src:"boat.jpg"
    },
    {
        name:"Terrestre",
        shortDes:"Por tierra ",
        descripcion:"Estamos ofreciendo servicio terrestre desde México y Centro América hacia El Salvador, en contenedores completos y cargas Consolidadas."
    , backgroundColor:"#bf6060",
        src:"truck.jpg"
    },
    {
        name:"Aereo",
        shortDes:"Por el aire ",
        descripcion:"Tenemos la oportunidad de ofrecer servicio aéreo de importación desde todas partes del mundo, procurando en todo momento dar un servicio veraz los embarques."
        ,backgroundColor:"#f6f7b7",
        src:"plane.jpg"
    },
    {
        name:"Ofrecemos",
        shortDes:"Por el aire ",
        descripcion:`-Atención las 24 horas del día.\n 
        -Servicio Personalizado.
        -Aseguramos su carga.
        -Modalidad en marítimo, aéreo y terrestre a su conveniencia. 
        -Movemos desde todos los lados del mundo.`
        ,backgroundColor:"#f6f7b7",
        src:"ofrecemos.jpg"
    }

]

const Info2 ={
    Vision: {descripccion:'Ser parte fundamental de la cadena de abastecimiento para el desarrollo de las importaciones y exportaciones a nivel mundial.',src:"vision.jpg"},
    Mision: {descripccion:'Procurar ofrecer un servicio de logística en excelencia para beneficio de nuestros clientes, garantizando la fluidez de la economía tanto en nuestro país, como con los demás países en donde tenemos presencia por medio de nuestros agentes.',src:"mision.jpg"},
    Valores: {descripccion:'Es muy importante para TCG la buena actitud hacia el servicio así como también la integridad en el manejo de los embarques.',src:"valores.jpg"},
    Quienes: {descripccion:'Somos una empresa de Logística que nació hace mas de 10 años para facilitar el movimiento de las importaciones y exportaciones a nivel mundial procurando dar siempre un servicio en excelencia, logrando que cada uno de nuestros clientes queden satisfechos.', src:"quienes.jpg"}
}
export {Info, Info2}
