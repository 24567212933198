import React, { useContext } from 'react';
import { motion } from 'framer-motion'
import { IntersectionObserver, IntersectionContext } from '../IntersectionObserver'



const ItemBlanco = (props) => {
    const { inView } = useContext(IntersectionContext);

    const variants = {
        hidden: {
            scale: 0,
            opacity: 0,

        },
        show: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: 1
            }
        }
    };


    return (

        <motion.section initial="hidden" animate={inView ? "show" : "hidden"} exit="hidden" variants={variants} className="wrapper style1 bolding">
            <div className="inner">
                <div className="flex flex-2">
                    <div className="col col1">
                        <div className="image round fit">
                            <img src={require(`../images/${props.info.src}`)} alt="" />
                        </div>
                    </div>
                    <div className="col col2 sizing">
                        <h3>{props.titulo}</h3>
                        <p>{props.info.descripccion}</p>
                    </div>
                </div>
            </div>
        </ motion.section>

    );
};

export default ItemBlanco;