import React from 'react';
import { motion } from 'framer-motion'
import Contacto from './Contacto'

const Footer = () => {
    return (
        <footer id="footer"> 
            <div className="copyright">
                
                <h1>Contactanos!</h1>
                <div style={{display:'flex', justifyContent:'center', paddingTop:'9em'}}>
                    <Contacto />
                </div>
                <ul className="icons" style={{marginTop:'10em'}} >
                   {/* <li><a href="#" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
                   */}
                    <li><motion.a  whileHover={{rotate:360 , scale:2, opacity:2, transition:{duration:1}}} href="https://www.facebook.com/Transporte-de-Carga-Global-1424321900927380/" className="icon fa-facebook"><span className="label">Facebook</span></motion.a></li>
                    <li><motion.a  whileHover={{rotate:360 , scale:2, opacity:2, transition:{duration:1}}} href="https://www.instagram.com/tcg.global/" className="icon fa-instagram"><span className="label">Instagram</span></ motion.a></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;