import React from 'react';


const logoPath = process.env.PUBLIC_URL + '/images/logo.png';

function MainNav(props) {
    return (
        <nav className="navbar navbar-expand-md navbar-light bg-light">
            <div className="container-fluid">
                < img src={logoPath} style={{ width: "15vw" }} alt="Not found" />
                
            </div>
        </nav>
    );
}

export default MainNav;