import React from 'react';
import { motion } from "framer-motion"

function item(props) {
    return (
        <motion.div className="flexHorizontal" style={{flexDirection:props.flex}} animate={{ x: 0 }} initial={{x:props.x}}>
            <div style={{marginRight:"2em", marginLeft:"2em"}}> 
                <p  className="moradito">{props.LittleTitle}</p>
                <h2 style={{fontSize:"22px"}}>{props.Title}</h2>
                <p style={{fontSize:"20px"}}>{props.Descripcion}</p>
            </div>
            <div>
                <motion.img whileHover={{scale:1.5}} src={props.img} style={{ width: "15vw" }} alt="Not found" />
            </div>
        </motion.div>
    );
}

export default item;