import React from "react";
//import './App.css';
import MainNav from "./MainNav";
import Item from "./item";
import "./assets/css/main.css";
import MainDashboard from "./components/MainDashboard";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Servicio from "./components/Servicio";

function App() {
  let cont = -1;
  const arr = [
    {
      LittleTitle: "Nosotros",
      Title: "¿Quienes somos? ",
      Descripcion:
        " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      img: process.env.PUBLIC_URL + "/images/logo.png",
    },
    {
      LittleTitle: "Mision",
      Title: "¿Que queremos lograr?",
      Descripcion:
        " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      img: process.env.PUBLIC_URL + "/images/logo.png",
    },
    {
      LittleTitle: "Vision",
      Title: "¿Como lo hacemos ? ",
      Descripcion:
        " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ",
      img: process.env.PUBLIC_URL + "/images/logo.png",
    },
    {
      LittleTitle: "Valores",
      Title: "¿Como somos? ",
      Descripcion:
        " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      img: process.env.PUBLIC_URL + "/images/logo.png",
    },
  ];
  const di = () => {
    return (
      <img
        style={{ height: "300px", width: "300px", objectFit: "cover" }}
        src={require(`./images/diega.jpg`)}
        alt=""
      />
    );
  };
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={MainDashboard} />
        <Route exact path="/diega" component={di} />
        <Route exact path="/:Servicio" component={Servicio} />
      </Switch>
    </Router>
  );
}

export default App;
