import React, { useContext } from 'react';
import { motion, useSpring } from 'framer-motion'
import { IntersectionContext } from '../IntersectionObserver'

const Servicios = () => {
    const { inView } = useContext(IntersectionContext);
    const variants = {
        hidden: {
            x: -600

        },
        show: {
            x: 0,
            transition: {
                duration: 1,
                delay: 0.4
            }
        }
    };
    return (
        <section className="wrapper style1" style={{paddingTop:"2em", overflowX:'visible !important'}}>
            <div className="inner">
                <header className="align-center">
                    <h2>Nuestros Servicios</h2>
                    <p>Nuestros servicios actuales</p>
                </header>
                <motion.div className="flex flex-3" initial="hidden" animate={inView ? "show" : "hidden"} exit="hidden" variants={variants}>
                    <div className="col align-center">
                        <motion.div whileHover={{ scale: 1.3 }} className="image round fit">
                            <a href="/Aereo"><img src={require("../images/plane.jpg")} alt="" /> </a>
                        </motion.div>
                        <h2>Aereo</h2>
                        <p >Servicio de importaciones y exportaciones aereas </p>
                        <a href="/Aereo" className="button">Learn More</a>
                    </div>
                    <div className="col align-center">
                        <motion.div whileHover={{ scale: 1.3 }} className="image round fit">
                            <a href="/Terrestre"> <img src={require("../images/truck.jpg")} alt="" /> </a>
                        </motion.div>
                        <h2>Terrestre</h2>
                        <p>Importaciones y Exportaciones para México y la región Centro Americana</p>
                        <a href="/Terrestre" className="button">Learn More</a>
                    </div>
                    <div className="col align-center">
                        <motion.div whileHover={{ scale: 1.3 }} className="image round fit">
                            <a href="/Maritimo">  <img src={require("../images/boat.jpg")} alt="" /> </a>
                        </motion.div>
                        <h2>Maritimo</h2>
                        <p>Servicio de importaciones y exportaciones maritimas</p>
                        <a href="/Maritimo" className="button">Learn More</a>
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default Servicios;