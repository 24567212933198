import React, { createRef, useEffect } from 'react';
import ItemAzul from './ItemAzul'
import ItemBlanco from './itemBlanco'
import Footer from './Footer'
import Servicios from './Servicios'
import { IntersectionObserver } from '../IntersectionObserver'
import '../assets/css/main.css'
import { motion } from 'framer-motion'
import { Info2, Info } from '../assets/js/info'
import { Link, animateScroll as scroll } from "react-scroll";
import Navbar from './Navbar'
import axios from 'axios'


const MainDashboard = () => {
    useEffect(() => {
        
        axios.get('https://diega-tcg-proyectoweb.herokuapp.com/')
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                }
            })
            .catch(err => console.log(err))
      }, []);


    const variants = {
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 0.6,
            },
        }),
        initial: {
            opacity: 0
        }
    }
    const transition={
        type: "spring",
        stiffness: 400,
        damping: 20,
        delay:1.5
    }
    return (
        <div id="main">
            <motion.section initial="initial" custom={1} id="banner" variants={variants} animate="visible" >
                <div className="bolding inner" style={{backgroundColor:'#1bb7f5', position:'fixed', top:'0px', zIndex:'100', color:'#fff'}} >
                    <Navbar />
                </div>
            </motion.section>
            
            <div id="quienes">
            <IntersectionObserver >
                <ItemBlanco titulo="¿Quienes somos?" info={Info2.Quienes} />
            </IntersectionObserver>
            </div>
            <div id="mision">
            <IntersectionObserver>
                <ItemAzul titulo="Mision" info={Info2.Mision} />
            </IntersectionObserver>
            </div>
            <div id="vision">
            <IntersectionObserver>
                <ItemBlanco titulo="Vision" info={Info2.Vision} />
            </IntersectionObserver>
            </div>
            <div id="valores">
            <IntersectionObserver>
                <ItemAzul titulo="Valores" info={Info2.Valores} />
            </IntersectionObserver>
            </div>
            <div id="anchor">
                <IntersectionObserver>
                    <Servicios />
                </IntersectionObserver>
               
            </div>
            <div id="contacto">
            <IntersectionObserver>
                <Footer />
                </IntersectionObserver>
            </div>

        </ div >
    );
};

export default MainDashboard;